import React, { FC } from 'react';
import classNames from 'classnames';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { Container } from 'layout';
import { setInlineColorStyle } from 'components/atoms/ColorPicker/helpers';
import { UmbracoColorRules } from 'components/atoms/ColorPicker/models';
import BreadCrumbs from 'components/elements/BreadCrumbs';
import Button from 'components/elements/Button';
import { BUTTON } from 'components/elements/Button/constants';
import Typography from 'components/elements/Typography';
import DynamicImage from 'components/helpers/DynamicImage';
import GatsbyImage from 'components/helpers/GatsbyImage';

import { IPropsHeroBanner } from './models';

import './HeroBanner.scss';

const HeroBanner: FC<IPropsHeroBanner> = ({
  data: [
    {
      bannerSettings: [
        {
          contentBgColorDesktop,
          contentBgColorMobile,
          imageBgColor,
          imageColorPlaceholder,
          sectionHeight,
          decorativeSvgMobile,
          decorativeSvgDesktop,
          additionalDecorativeSvgDesktop,
        },
      ],
      text,
      ctaButton,
      title,
      image,
      mobilebannerimage,
      fullwidthbanner,
    },
  ],
  breadcrumbs,
  isShowBreadcrumbsMobile,
  isShowBreadcrumbsDesktop,
  className,
}) => {
  const { isMobile, isTablet, isDesktop } = useScreenRecognition();

  const heroBannerClasses = classNames(
    'hero-banner',
    {
      [`hero-banner-content-left-color-desktop--${contentBgColorDesktop?.[0].color.label}`]:
        contentBgColorDesktop?.[0].color.label,
      [`hero-banner-content-left-color-mobile--${contentBgColorMobile?.[0].color.label}`]:
        contentBgColorMobile?.[0].color.label,
      [`hero-banner-size--${sectionHeight}`]: sectionHeight,
      [`hero-banner-size--full-width`]: fullwidthbanner,
    },
    className
  );

  const conditionalImageBgColor =
    imageBgColor?.[0]?.color &&
    setInlineColorStyle(imageBgColor?.[0]?.color, UmbracoColorRules.backgroundColor);

  const conditionalImagePlaceholderColor =
    imageColorPlaceholder?.[0]?.color &&
    setInlineColorStyle(imageColorPlaceholder?.[0]?.color, UmbracoColorRules.backgroundColor);
    

  return (
    <section data-testid="HeroBanner" className={heroBannerClasses}>
      {fullwidthbanner && fullwidthbanner === '1' ? null : (
        <div className="hero-banner__decor">
          <GatsbyImage
            image={isDesktop ? decorativeSvgDesktop : decorativeSvgMobile}
            className="hero-banner__decor-main"
          />
          {additionalDecorativeSvgDesktop?.svg && isDesktop ? (
            <GatsbyImage
              image={additionalDecorativeSvgDesktop}
              className="hero-banner__decor-additional"
            />
          ) : null }
        </div>
      )}
      
      <Container className="hero-banner__content">
        {(isMobile && isShowBreadcrumbsMobile) || (!isMobile && isShowBreadcrumbsDesktop) ? (
          <BreadCrumbs className="hero-banner__breadcrumbs" breadcrumbs={breadcrumbs} />
        ) : null}
        <Typography data={title} customClass="hero-banner__title" />
        {text?.length ? <Typography data={text} customClass="hero-banner__text" /> : null}
        {ctaButton?.length ? (
          <Button
            data={[{ ...ctaButton[0], size: isDesktop ? BUTTON.SIZE.LARGE : BUTTON.SIZE.SMALL }]}
            className="hero-banner__cta"
          />
        ) : null}
      </Container>
      <div
        className={classNames('hero-banner__right', {
          [`hero-banner__right-color-${imageBgColor?.[0].color.label}`]:
            imageBgColor?.[0].color.label,
        })}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...conditionalImageBgColor}
      >
        {fullwidthbanner && fullwidthbanner === '1' ? (
          <div
            className={classNames('hero-banner__image', {
              [`hero-banner__image-color-${imageColorPlaceholder?.[0]?.color.label}`]:
                imageColorPlaceholder?.length && imageColorPlaceholder?.[0]?.color?.label,
            })}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...conditionalImagePlaceholderColor}
          >
            <DynamicImage image={image} isLazyLoading={false} />
          </div>
        ) : null }

        {image && isDesktop ? (
          <div
            className={classNames('hero-banner__image', {
              [`hero-banner__image-color-${imageColorPlaceholder?.[0]?.color.label}`]:
                imageColorPlaceholder?.length && imageColorPlaceholder?.[0]?.color?.label,
            })}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...conditionalImagePlaceholderColor}
          >
            <DynamicImage image={image} isLazyLoading={false} />
          </div>
        ) : null}

        {image && mobilebannerimage && isMobile ? (
          <div className={classNames('hero-banner__mobile_image')}>
            <DynamicImage image={mobilebannerimage} isLazyLoading={false} />
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default HeroBanner;
