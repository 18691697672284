import React, { FC } from 'react';
import Img from 'gatsby-image/withIEPolyfill';

import useScreenRecognition from 'hooks/useScreenRecognition';

import { IDynamicImageProps } from './models';
import './DynamicImage.scss';

const DynamicImage: FC<IDynamicImageProps> = ({
  isLazyLoading = true,
  customAlt,
  className = '',
  objectFit,
  objectPosition,
  image,
  ...restProps
}) => {
  const { isDesktop, isTablet, isInit } = useScreenRecognition();
  const loading = isLazyLoading ? 'lazy' : 'eager';

  let imageToRender;

  if (isInit) {
    imageToRender = image.desktopImage;
  } else if (isTablet) {
    imageToRender = image?.tabletImage;
  } else if (isDesktop) {
    imageToRender = image?.desktopImage;
  } else {
    imageToRender = image?.mobileImage;
  }

  return imageToRender?.gatsbyImage?.childImageSharp?.fluid ? (
    <Img
      data-testid="DynamicImage"
      className={className}
      alt={imageToRender.altText || customAlt}
      objectFit={objectFit || 'cover'}
      objectPosition={objectPosition || '50% 50%'}
      fluid={imageToRender.gatsbyImage.childImageSharp.fluid}
      loading={loading}
      {...restProps}
    />
  ) : null;
};

export default DynamicImage;
