import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import useScreenRecognition from 'hooks/useScreenRecognition';

import IconCustom from 'components/helpers/IconCustom';

import { IPropsBreadCrumbs } from './models';

import './BreadCrumbs.scss';

const BreadCrumbs: FC<IPropsBreadCrumbs> = ({ breadcrumbs, className }) => {
  const { isMobile } = useScreenRecognition();

  const itemsToRender = isMobile ? [breadcrumbs[breadcrumbs.length - 2]] : breadcrumbs;

  return (
    <ul
      data-testid="BreadCrumbs"
      className={classNames('breadcrumbs', {
        [className || '']: className,
      })}
      itemScope
      itemType="http://schema.org/BreadcrumbList"
    >
      {itemsToRender.map(({ name, link, order }) => (
        <li
          key={`list-${name}`}
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
        >
          {link ? (
            <Link className="breadcrumbs__link" aria-label={name} to={link} itemProp="item">
              <span className="breadcrumbs__label" itemProp="name">
                {name}
              </span>
              <IconCustom icon="chevron-right-medium" className="breadcrumbs__icon" />
            </Link>
          ) : (
            <span className="breadcrumbs__label" itemProp="name">
              {name}
            </span>
          )}
          <meta itemProp="position" content={order} />
        </li>
      ))}
    </ul>
  );
};
export default BreadCrumbs;
